import React, { PureComponent } from 'react'
import { validatePro, updateProProfile } from './Redux/Actions'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import {
  getFirstName,
  getLastName,
  getMobileNumber,
} from 'src/common/CurrentPro/Data'
import EditAccountForm from './EditAccountForm'
import EditFormDialog from './EditFormDialog'

export const EDIT_ACCOUNT_FORM = 'EDIT_ACCOUNT_FORM'

@connect(
  state => ({
    initialValues: {
      first_name: getFirstName(state),
      last_name: getLastName(state),
      mobile_number: getMobileNumber(state),
    },
  }),
  {
    updateProProfile,
  }
)
@reduxForm({
  form: EDIT_ACCOUNT_FORM,
  fields: ['first_name', 'last_name', 'mobile_number'],
  enableReinitialize: true,
  validate: validatePro,
})
export default class EditAccountDialog extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      updating: false,
    }
  }

  submitForm(values) {
    const { updateProProfile, onClose } = this.props

    this.setState({ updating: true })
    updateProProfile(values)
      .then(() => {
        onClose()
      })
      .finally(() => {
        this.setState({ updating: false })
      })
  }

  render() {
    const { open, onClose, handleSubmit } = this.props
    const { updating } = this.state

    return (
      <EditFormDialog
        updating={updating}
        open={open}
        onClose={() => onClose()}
        onSubmit={handleSubmit(values => this.submitForm(values))}
        title="Account Settings"
      >
        <EditAccountForm disabled={updating} />
      </EditFormDialog>
    )
  }
}
