import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  withStyles,
} from '@material-ui/core'
import PublishDialogImage from '../../../images/PublishDialogImage'

const styles = {
  container: {
    height: 700
  },
  publishImage: {
    padding: '10px 0',
  },
  buttonPadding: {
    padding: 16
  },
  contentDialog: {
    maxWidth: 400
  }
}

function CongratsDialog({ open, onClose, classes }) {
  return (
    <Dialog open={open} onClose={() => onClose()} className={classes.container}>
      <DialogTitle disableTypography>
        <Typography variant="h6">Your site is being published!</Typography>
      </DialogTitle>
      <DialogContent className={classes.contentDialog}>
        <Grid item container justify="center" className={classes.publishImage}>
          <PublishDialogImage />
        </Grid>
        <Typography variant="body2" align="center">
          Sit back and relax. All of your changes will be reflected on
          your live site in a few minutes.
        </Typography>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonPadding}>
          <Button color="primary" onClick={() => onClose()}>
            OK
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

CongratsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

CongratsDialog.defaultProps = {
  open: false
}

export default withStyles(styles)(CongratsDialog)
