import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Header from './Header/Header'
import { fetchCompanyProfile } from 'src/Resources/CompanyProfile/Data'
import { fetchBillingPlan } from 'src/Resources/BillingPlan/Data'
import { getCompanyEmail } from 'src/Resources/Organization/Data'
import CompanyProfileDesktop from '../CompanyProfile/CompanyProfileDesktop'
import CompanyProfileMobile from '../CompanyProfile/CompanyProfileMobile'
import {
  proIsLoggedIn,
  proAvatar,
  getCurrentPro,
} from 'src/common/CurrentPro/Data'
import CurrentPro from 'src/common/CurrentPro/CurrentPro'
import { FetchData } from 'data-fetcher'
import { withGoogleMapsAPI } from 'src/common/GoogleMaps/withGoogleMapsAPI'
import { fetchScheduleAvailability } from '../CompanyProfile/Book/BookingWindows/EditBookingWindows/Redux/Data'
import ErrorDialog from '../../common/ErrorDialog/ErrorDialog'
import EditChat from '../CompanyProfile/Chat/EditChat'
import WithChatBubble from '../CompanyProfile/Chat/WithChatBubble'
import { getEditChatColor } from 'src/Resources/CompanyProfile/Theme/Data'

const styles = {
  content: {
    marginTop: 72,
  },
  blurContent: {
    filter: `blur(5px)`,
  },
}

@CurrentPro(true)
@FetchData(fetchCompanyProfile, true)
@FetchData(fetchScheduleAvailability, true)
@FetchData(fetchBillingPlan, true)
@connect(state => ({
  pro: getCurrentPro(state),
  proIsLoggedIn: proIsLoggedIn(state),
  proAvatar: proAvatar(state),
  email: getCompanyEmail(state),
  chatColor: getEditChatColor(state),
}))
@withStyles(styles)
@withGoogleMapsAPI()
export default class Edit extends PureComponent {
  render() {
    const { chatColor, classes, proIsLoggedIn, proAvatar, pro, email } = this.props
    if (window && window.HousecallChat) {
      window.HousecallChat.updateChatColor(chatColor)
    }

    return (
      <div className={proIsLoggedIn ? '' : classes.blurContent}>
        {proIsLoggedIn && (
          <Header
            avatarUrl={proAvatar}
            firstName={pro.first_name}
            lastName={pro.last_name}
            email={email}
          />
        )}
        <div className={proIsLoggedIn ? classes.content : ''}>
          <Fragment>
            <Hidden mdDown>
              <CompanyProfileDesktop
                stickyHeader={false}
                inEditMode={proIsLoggedIn === true}
              />
            </Hidden>
            <Hidden lgUp>
              <CompanyProfileMobile
                stickyHeader={false}
                inEditMode={proIsLoggedIn === true}
              />
            </Hidden>
          </Fragment>
        </div>
        <ErrorDialog />
        {proIsLoggedIn &&
          <WithChatBubble color={chatColor}>
            <EditChat />
          </WithChatBubble>
        }
      </div>
    )
  }
}
