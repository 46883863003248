import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { withStyles } from '@material-ui/core';

const styles = {
  'cls1': {
    fill: '#37474f',
    opacity:0.05
  },
  'cls2': {
    opacity:0.05
  },
  'cls3': {
    fill: '#37474f'
  },
  'cls4': {
    fill: '#37474f',
    opacity:0.24
  },
  'cls5': {
    opacity:0.5
  },
  'cls6': {
    fill: 'none',
    stroke:'#37474f',
    'stroke-miterlimit':10,
    opacity:0.36
  },
  'cls7': {
    stroke:'#37474f',
    'stroke-miterlimit':10,
    fill: '#fff'
  },
  'cls8': {
    fill: '#37474f',
    stroke:'#37474f',
    'stroke-miterlimit':10
  },
  'cls9': {
    fill:'#2196f3',
    stroke:'#2196f3',
    'stroke-linecap':'round',
    'stroke-linejoin':'round',
    'stroke-width': 1.5
  },
  'cls10': {
    fill: '#37474f',
    stroke:'#37474f',
    'stroke-linecap':'round',
    'stroke-linejoin':'round',
    'stroke-width': 1.5
  },
  'cls11': {
    fill: '#37474f',
    opacity:0.36
  }
}

function PublishDialogImage({classes, ...props}) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" style={{width: '80%', height: '100%'}} viewBox="0 0 218.9 171.57" {...props}>
      <title>published</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <ellipse className={classes.cls1} cx="109.34" cy="167.07" rx="45" ry="4.5"/>
          <g className={classes.cls2}>
            <path className={classes.cls3} d="M122,30.8C107.1,20.64,91.68,10.24,74,6.67S35.63,8.08,25.88,23.26c-6.44,10-7.15,22.65-6.56,34.56.32,6.48,1,13,3.34,19.09,9.9,25.51,44.62,32.58,58.15,56.37,2.81,4.95,4.65,10.55,8.54,14.7,8.74,9.31,24.14,7.66,35.75,2.36s22.17-13.56,34.75-15.74c14.75-2.55,33.39,2.33,42.25-9.73,6.34-8.63,2.94-21-2.9-30-9.34-14.38-23.75-24.56-37.8-34.38-16-11.16-25.69-20.06-41.65-31.22"/>
          </g>
          <circle className={classes.cls4} cx="169.64" cy="54.56" r="2.02"/>
          <circle className={classes.cls4} cx="180.94" cy="74.66" r="2.02"/>
          <circle className={classes.cls4} cx="216.87" cy="90.15" r="2.02"/>
          <circle className={classes.cls4} cx="69.46" cy="27.79" r="2.02"/>
          <circle className={classes.cls4} cx="96.13" cy="2.02" r="2.02"/>
          <circle className={classes.cls4} cx="35.28" cy="37.14" r="2.02"/>
          <circle className={classes.cls4} cx="2.02" cy="76.14" r="2.02"/>
          <g className={classes.cls5}>
            <circle className={classes.cls6} cx="166.19" cy="27.19" r="4.45"/>
            <circle className={classes.cls6} cx="210.06" cy="58.45" r="4.45"/>
            <circle className={classes.cls6} cx="176.89" cy="134.01" r="4.45"/>
            <circle className={classes.cls6} cx="29.23" cy="18.02" r="4.45"/>
            <circle className={classes.cls6} cx="41.6" cy="89.89" r="4.45"/>
            <circle className={classes.cls6} cx="52.88" cy="119.08" r="4.45"/>
          </g>
          <circle className={classes.cls4} cx="46.04" cy="131.9" r="2.02"/>
          <circle className={classes.cls4} cx="185.87" cy="117.15" r="2.02"/>
          <circle className={classes.cls7} cx="108.84" cy="84.07" r="48"/>
          <rect className={classes.cls8} x="107.73" y="113.06" width="1.94" height="12.54"/>
          <rect className={classes.cls8} x="107.73" y="41.21" width="1.94" height="12.54"/>
          <rect className={classes.cls8} x="66.5" y="82.43" width="12.54" height="1.94"/>
          <rect className={classes.cls8} x="138.36" y="82.43" width="12.54" height="1.94"/>
          <rect className={classes.cls8} x="84.09" y="113.33" width="12.54" height="1.94" transform="translate(-54.06 133.62) rotate(-59.29)"/>
          <rect className={classes.cls8} x="120.77" y="51.54" width="12.53" height="1.94" transform="translate(17.01 134.92) rotate(-59.29)"/>
          <rect className={classes.cls8} x="76.84" y="58.8" width="1.94" height="12.54" transform="translate(-17.87 98.74) rotate(-59.29)"/>
          <rect className={classes.cls8} x="138.62" y="95.48" width="1.94" height="12.53" transform="translate(-19.16 169.84) rotate(-59.3)"/>
          <rect className={classes.cls8} x="71.8" y="101.21" width="12.54" height="1.94" transform="translate(-41.91 55.9) rotate(-31.53)"/>
          <rect className={classes.cls8} x="133.06" y="63.66" width="12.53" height="1.94" transform="translate(-13.23 82.39) rotate(-31.53)"/>
          <rect className={classes.cls8} x="88.95" y="46.51" width="1.94" height="12.53" transform="translate(-14.33 54.79) rotate(-31.51)"/>
          <rect className={classes.cls8} x="126.51" y="107.77" width="1.94" height="12.54" transform="translate(-40.8 83.41) rotate(-31.5)"/>
          <path className={classes.cls8} d="M108.28,132.08A48.68,48.68,0,1,1,157,83.41,48.73,48.73,0,0,1,108.28,132.08Zm0-95.41A46.74,46.74,0,1,0,155,83.41,46.79,46.79,0,0,0,108.28,36.67Z"/>
          <rect className={classes.cls9} x="107.31" y="60.29" width="1.94" height="20.51"/>
          <rect className={classes.cls9} x="110.72" y="83.14" width="14.28" height="1.94"/>
          <path className={classes.cls10} d="M108.28,86.82a3.41,3.41,0,1,1,3.41-3.41A3.41,3.41,0,0,1,108.28,86.82Zm0-4.88a1.47,1.47,0,1,0,1.47,1.47A1.47,1.47,0,0,0,108.28,81.94Z"/>
          <circle className={classes.cls11}cx="47.87" cy="101.15" r="2.02"/>
          <circle className={classes.cls11}cx="47.87" cy="101.15" r="2.02"/>
        </g>
      </g>
    </SvgIcon>
  )
}

export default withStyles(styles)(PublishDialogImage)