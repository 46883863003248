import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { clearComponent, ERROR_DIALOG } from './Actions'
import { getErrorDialogMessage } from './Data'
import { isPresent } from '../ObjectHelper'
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  actionContainer: {
    padding: 16,
  },
})

@connect(
  state => ({
    message: getErrorDialogMessage(state),
  }),
  { clearComponent }
)
@withStyles(styles)
export default class EphemeralErrorDialog extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  componentDidUpdate() {
    if (isPresent(this.props.message) != this.state.open) {
      this.setState({ open: this.props.message })
    }
  }

  handleClose() {
    this.props.clearComponent(ERROR_DIALOG)
    this.setState({ open: false })
  }

  render() {
    const { message, classes } = this.props

    return (
      <Dialog
        open={isPresent(message)}
        onClose={() => {
          this.handleClose()
        }}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6" color="error">
            Error
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionContainer}>
          <Button
            color="primary"
            onClick={() => {
              this.handleClose()
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
