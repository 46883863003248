import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Drawer } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Typography from 'housecall-ui/dist/library/DataDisplay/Typography'
import Grid from 'housecall-ui/dist/library/Layout/Grid'
import IconButton from 'housecall-ui/dist/library/Buttons/IconButton'
import ThemeColor from './ThemeColor'

const drawerWidth = 280

const styles = {
  drawerPaper: {
    width: drawerWidth,
    padding: 16,
  },
  icons: {
    color: 'black',
  },
}

function ThemeDrawer({ classes, open, closeThemeDrawer }) {
  return (
    <Drawer
      variant="persistent"
      open={open}
      classes={{ paper: classes.drawerPaper }}
    >
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <Typography variant="subtitle1">Configure theme</Typography>
        </Grid>
        <Grid item container xs={2}>
          <IconButton onClick={() => closeThemeDrawer()}>
            <CloseIcon className={classes.icons} />
          </IconButton>
        </Grid>
      </Grid>
      <ThemeColor closeThemeDrawer={() => closeThemeDrawer()} />
    </Drawer>
  )
}

ThemeDrawer.propTypes = {
  open: PropTypes.bool,
}

ThemeDrawer.defaultProps = {
  open: false,
}

export default withStyles(styles)(ThemeDrawer)
