import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Typography from 'housecall-ui/dist/library/DataDisplay/Typography'
import Button from 'housecall-ui/dist/library/Buttons/Button'
import Grid from 'housecall-ui/dist/library/Layout/Grid'
import ColorPicker from './ColorPicker'
import { updateTheme, setThemePrimaryColor } from './Redux/Actions'
import { defaultPrimaryColor } from 'src/Resources/CompanyProfile/Theme/Data'

function ThemeColor({ updateTheme, setThemePrimaryColor, closeThemeDrawer }) {
  const [isSaving, setIsSaving] = useState(false)

  function onColorSave() {
    setIsSaving(true)
    closeThemeDrawer()
    updateTheme().finally(() => {
      setIsSaving(false)
    })
  }

  return (
    <Grid container>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Typography variant="body2">Accent color</Typography>
        </Grid>
        <Grid item xs={12}>
          <ColorPicker />
        </Grid>
      </Grid>
      <Grid item container spacing={8} justify="flex-end" xs={12}>
        <Grid item>
          <Button
            disabled={isSaving}
            onClick={() => setThemePrimaryColor(defaultPrimaryColor)}
          >
            Restore to default
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={isSaving}
            variant={'contained'}
            color="primary"
            onClick={() => onColorSave()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

const HOCs = compose(
  connect(
    null,
    { updateTheme, setThemePrimaryColor }
  )
)

export default HOCs(ThemeColor)
