import React from 'react'
import { TwitterPicker } from 'react-color'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { setThemePrimaryColor } from './Redux/Actions'
import { getThemePrimaryColor } from 'src/Resources/CompanyProfile/Theme/Data'

const styles = {
  noBoxShadow: {
    boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px !important',
  },
}

const colorPickerOptions = [
  '#9c27b0',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#f44336',
  '#e91e63',
]

const ColorPicker = ({ classes, setThemePrimaryColor, primaryColor }) => {
  return (
    <TwitterPicker
      className={classes.noBoxShadow}
      color={primaryColor}
      width={260}
      triangle={'hide'}
      colors={colorPickerOptions}
      onChangeComplete={({ hex }) => setThemePrimaryColor(hex)}
    />
  )
}

const HOCs = compose(
  connect(
    state => ({
      primaryColor: getThemePrimaryColor(state),
    }),
    { setThemePrimaryColor }
  ),
  withStyles(styles)
)

export default HOCs(React.memo(ColorPicker))
