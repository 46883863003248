import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from 'housecall-ui/dist/library/PageElements/Dialog'
import DialogActions from 'housecall-ui/dist/library/PageElements/DialogActions'
import DialogContent from 'housecall-ui/dist/library/PageElements/DialogContent'
import Typography from 'housecall-ui/dist/library/DataDisplay/Typography'
import Grid from 'housecall-ui/dist/library/Layout/Grid'
import Switch from 'housecall-ui/dist/library/SelectionControls/Switch'
import { CancelSaveButtons } from 'housecall-ui'
import { getShowChat } from '../../../Resources/WebsiteContent/Data'
import { updateWebsiteContent } from 'src/Resources/WebsiteContent/Actions'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'
import {
  getBillingPlanTier,
  TIER_SUBSCRIPTION,
} from '../../../Resources/BillingPlan/Data'

const styles = {
  titleText: {
    fontSize: 24,
    lineHeight: 2,
  },
}

function EditChatDialog({
  classes,
  open,
  onClose,
  showChat,
  billingPlanTier,
  updateWebsiteContent,
  displayErrorDialog,
}) {
  const [isSaving, setIsSaving] = useState(false)
  const [showChatToggleState, setShowChatToggleState] = useState(showChat)

  function onSaveClick() {
    setIsSaving(true)
    updateWebsiteContent({ show_chat: showChatToggleState })
      .catch(() => {
        displayErrorDialog('Failed to update chat settings. Please try again')
      })
      .finally(() => {
        onClose()
        setIsSaving(false)
      })
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      disableBackdropClick
      fullWidth
    >
      <DialogContent>
        <Grid container spacing={16}>
          <Grid item container xs={12}>
            <Grid item xs={9} sm={11}>
              <Typography className={classes.titleText}>
                Chat setting
              </Typography>
            </Grid>
            {billingPlanTier === TIER_SUBSCRIPTION && (
              <Grid item xs={3} sm={1}>
                <Switch
                  color="primary"
                  checked={showChatToggleState}
                  onChange={() => setShowChatToggleState(!showChatToggleState)}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {billingPlanTier === TIER_SUBSCRIPTION ? (
              <Typography>
                Chat allows your customers to directly message you from your
                website. To turn it on/off, just use the toggle above
              </Typography>
            ) : (
              <Typography>
                Chat allows your customers to directly message you from your
                website. This feature is only available on the Fixed plan.
                <br /> <br />
                Want to change you plan? Email us at{' '}
                <a href="mailto:support@housecallpro.com">
                  support@housecallpro.com
                </a>
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CancelSaveButtons
          saveButtonVariant="text"
          onCancelClick={() => onClose()}
          onSaveClick={() => onSaveClick()}
          isSaving={isSaving}
        />
      </DialogActions>
    </Dialog>
  )
}

EditChatDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

EditChatDialog.defaultPropts = {
  open: false,
}

const HOCs = compose(
  connect(
    state => ({
      showChat: getShowChat(state),
      billingPlanTier: getBillingPlanTier(state),
    }),
    { updateWebsiteContent, displayErrorDialog }
  ),
  withStyles(styles)
)

export default HOCs(EditChatDialog)
