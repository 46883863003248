import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import { EditAndDelete } from 'housecall-ui'
import EditChatDialog from './EditChatDialog'

const styles = {
  editIcon: {
    position: 'fixed',
    bottom: 20,
    right: 0,
    width: 130,
    height: 65,
    zIndex: 1210,
    lineHeight: '65px',
  },
}

function EditChat({ classes }) {
  const [showEditDialog, setShowEditDialog] = useState(false)

  return (
    <div className={classes.editIcon}>
      <EditAndDelete
        canEdit={true}
        canDelete={false}
        onEditClick={() => setShowEditDialog(true)}
      />
      {showEditDialog && (
        <EditChatDialog
          open={showEditDialog}
          onClose={() => setShowEditDialog(false)}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(EditChat)
