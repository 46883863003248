import { updateThemeApi } from './Api'
import { receivedCompanyProfileData } from 'src/Resources/CompanyProfile/Data'
import { getErrorMessage } from 'src/common/ErrorDialog/ErrorHandler'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'
import { getThemeConfiguration } from 'src/Resources/CompanyProfile/Theme/Data'

export function updateTheme() {
  return (dispatch, getState) => {
    return updateThemeApi(getThemeConfiguration(getState()))
      .then(response => {
        dispatch(receivedCompanyProfileData({ theme: response.data }))
      })
      .catch(error => {
        dispatch(displayErrorDialog(getErrorMessage(error)))
      })
  }
}

export function setThemePrimaryColor(color) {
  return (dispatch, getState) => {
    const themeConfiguration = getThemeConfiguration(getState())
    let updatedThemeConfiguration = JSON.parse(
      JSON.stringify(themeConfiguration)
    )
    updatedThemeConfiguration.palette.primary.main = color

    const theme = {
      object: 'website_theme',
      theme_configuration: updatedThemeConfiguration,
    }

    dispatch(
      receivedCompanyProfileData({
        theme: theme,
      })
    )
  }
}
