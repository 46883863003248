import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import FormTextField from 'src/common/Form/FormTextField'

function EditAccountForm({ disabled }) {
  return (
    <Fragment>
      <FormTextField
        name="first_name"
        label="First name"
        margin="normal"
        variant="outlined"
        disabled={disabled}
        fullWidth
        autoFocus
        focusOnNextFieldUponEnter
      />
      <FormTextField
        name="last_name"
        label="Last name"
        margin="normal"
        variant="outlined"
        disabled={disabled}
        fullWidth
        focusOnNextFieldUponEnter
      />
      <FormTextField
        name="mobile_number"
        label="Mobile phone"
        margin="normal"
        variant="outlined"
        normalize="phone"
        disabled={disabled}
        fullWidth
      />
    </Fragment>
  )
}

EditAccountForm.propTypes = {
  disabled: PropTypes.bool,
}

EditAccountForm.defaultProps = {
  disabled: false,
}

export default EditAccountForm
