import React, { useState } from 'react'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { format, parseISO } from 'date-fns/esm'
import AppBar from 'housecall-ui/dist/library/Toolbar/AppBar'
import Typography from 'housecall-ui/dist/library/DataDisplay/Typography'
import Button from 'housecall-ui/dist/library/Buttons/Button'
import IconButton from 'housecall-ui/dist/library/Buttons/IconButton'
import Grid from 'housecall-ui/dist/library/Layout/Grid'
import { Toolbar, Hidden } from '@material-ui/core'
import ThemeIcon from '@material-ui/icons/ColorLens'
import { AvatarButtonWithSettingsPopover } from 'housecall-ui'
import { publishWebsite } from './Redux/Actions'
import CongratsDialog from './CongratsDialog'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'
import { grey } from '@material-ui/core/colors'
import { logout } from './Redux/Actions'
import EditOrganizationDialog from '../../EditProfileDialog/EditOrganizationDialog'
import EditAccountDialog from '../../EditProfileDialog/EditAccountDialog'
import { proIsLoggedIn } from './../../../common/CurrentPro/Data'
import { getPublishedAt } from '../../../Resources/WebsiteContent/Data'
import { isPresent } from 'src/common/ObjectHelper'
import ThemeDrawer from '../ThemeDrawer/ThemeDrawer'

const styles = theme => ({
  publishButton: {
    marginLeft: 20,
    backgroundColor: '#FFFFFF',
    '&:disabled': {
      color: grey[500],
      backgroundColor: grey[400],
    },
  },
  previewButton: {
    marginRight: 16,
  },
  buttonGrid: {
    [theme.breakpoints.only('xs')]: {
      flexGrow: 1,
    },
  },
  appbarContainer: {
    zIndex: 1200,
  },
  publishedAt: {
    paddingLeft: 15,
  },
})

function Header({
  avatarUrl,
  firstName,
  lastName,
  email,
  classes,
  proIsLoggedIn,
  publishedAt,
  publishWebsite,
  displayErrorDialog,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [showProcessDialog, setShowProcessDialog] = useState(false)
  const [isCompanySettingsOpen, setIsCompanySettingsOpen] = useState(false)
  const [isAccountSettingsOpen, setIsAccountSettingsOpen] = useState(false)
  const [showThemeDrawer, setShowThemeDrawer] = useState(false)

  const onPublishClick = () => {
    publishWebsite()
      .then(() => {
        setShowProcessDialog(true)
        setIsButtonDisabled(true)
      })
      .catch(() => {
        displayErrorDialog('Something went wrong. Please try again.')
      })
  }
  return (
    <AppBar position="fixed" className={classes.appbarContainer}>
      <Toolbar>
        <Grid container alignItems="center" justify="center">
          <Hidden xsDown>
            <Grid item>
              <Typography variant="h6" color="inherit" align="center">
                You're currently in edit mode
              </Typography>
            </Grid>
          </Hidden>
          <Grid item className={classes.buttonGrid}>
            <Button
              variant="contained"
              disabled={isButtonDisabled}
              className={classes.publishButton}
              onClick={() => onPublishClick()}
            >
              Publish
            </Button>
          </Grid>
          <Hidden xsDown>
            {isPresent(publishedAt) && (
              <Grid item className={classes.publishedAt}>
                <Typography variant="caption" color="inherit">
                  {`Last published: ${format(
                    parseISO(publishedAt),
                    'h:mm a, MMM d, yyyy'
                  )}`}
                </Typography>
              </Grid>
            )}
          </Hidden>
        </Grid>
        <IconButton
          color="secondary"
          aria-label="Preview"
          className={classes.previewButton}
          onClick={() => setShowThemeDrawer(!showThemeDrawer)}
        >
          <ThemeIcon />
        </IconButton>
        {proIsLoggedIn && (
          <AvatarButtonWithSettingsPopover
            avatarUrl={avatarUrl}
            firstName={firstName}
            lastName={lastName}
            email={email}
            onCompanySettingsClicked={() => {
              setIsCompanySettingsOpen(true)
              setIsAccountSettingsOpen(false)
            }}
            onAccountSettingsClicked={() => {
              setIsAccountSettingsOpen(true)
              setIsCompanySettingsOpen(false)
            }}
            onLogOut={() => logout()}
          />
        )}
        <EditOrganizationDialog
          open={isCompanySettingsOpen}
          onClose={() => setIsCompanySettingsOpen(false)}
        />
        <EditAccountDialog
          open={isAccountSettingsOpen}
          onClose={() => setIsAccountSettingsOpen(false)}
        />
        <CongratsDialog
          open={showProcessDialog}
          onClose={() => {
            setShowProcessDialog(false)
            setIsButtonDisabled(false)
          }}
        />
      </Toolbar>
      <ThemeDrawer
        open={showThemeDrawer}
        closeThemeDrawer={() => setShowThemeDrawer(false)}
      />
    </AppBar>
  )
}

const HOCs = compose(
  connect(
    state => ({
      proIsLoggedIn: proIsLoggedIn(state),
      publishedAt: getPublishedAt(state),
    }),
    { publishWebsite, displayErrorDialog }
  ),
  withStyles(styles)
)

Header.propTypes = {
  avatarUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
}
export default HOCs(Header)
